.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  line-height: 1.6875;
  cursor: pointer;
  color: #d65f00;
  padding: 20px 70px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1px solid #3e4647;
  font-family: 'Alegreya', serif;
  letter-spacing: 0.08em;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.5;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.button:hover,
.button:focus {
  color: #FFF;
  background-color: #d65f00;
  border-color: #d65f00;
}
.button:active {
  background-color: #8a3d00;
}
.navigation div.navi {
  float: left;
  z-index: 5;
}
.navigation div.navi > .item {
  float: left;
}
.navigation div.navi > .item > .menu {
  white-space: nowrap;
  display: block;
  text-decoration: none;
  border: unset;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (min-width: 1250px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.navigation div.sub2 > .item {
  margin: 0 10px;
  position: relative;
}
.navigation div.sub2 > .item.init {
  margin-left: 0;
}
.navigation div.sub2 > .item.exit {
  margin-right: 0;
}
.navigation div.sub2 > .item:hover > .menu,
.navigation div.sub2 > .item:focus > .menu {
  color: #d65f00;
  background-color: #f5f4f0;
}
.navigation div.sub2 > .item > .menu {
  font-size: 17px;
  line-height: 1.64705882;
  color: #3e4647;
  word-wrap: unset;
  padding: 5px 17px;
  position: relative;
}
.navigation div.sub2 > .item > .menu.path {
  color: #d65f00;
  background-color: #f5f4f0;
}
.navigation div.sub2 > .item > .menu.path:after {
  content: '';
  position: absolute;
  bottom: -2px;
  width: calc(100% - 34px);
  left: 17px;
  right: 17px;
  height: 2px;
  background-color: #3e4647;
}
.navigation div.sub3 {
  position: absolute;
  top: 100%;
  background-color: #f5f4f0;
  display: none;
  min-width: 190px;
  padding-bottom: 14px;
  box-sizing: border-box;
}
.navigation div.sub3:after {
  content: '';
  position: absolute;
  bottom: 14px;
  width: 50%;
  left: 17px;
  right: 17px;
  height: 2px;
  background-color: #3e4647;
}
.navigation div.sub3:before {
  content: '';
  position: absolute;
  top: 0;
  width: 50%;
  left: 17px;
  right: 17px;
  height: 2px;
  background-color: #3e4647;
}
.navigation div.sub3 > .item {
  width: 100%;
  position: relative;
  margin-bottom: 4px;
}
.navigation div.sub3 > .item.init {
  margin-top: 10px;
}
.navigation div.sub3 > .item.exit {
  margin-bottom: 10px;
}
.navigation div.sub3 > .item:hover > .menu,
.navigation div.sub3 > .item:focus > .menu {
  color: #d65f00;
}
.navigation div.sub3 > .item > .menu {
  font-size: 17px;
  line-height: 1.29411765;
  color: #3e4647;
  padding: 3px 17px;
}
.navigation div.sub3 > .item > .menu.path {
  color: #d65f00;
}
.navigation div.sub2 > .item:hover div.sub3 {
  display: block;
}
.footarea {
  width: 107.46268657%;
  margin-left: -3.73134328%;
}
.footpart {
  margin-left: 3.47222222%;
  margin-right: 3.47222222%;
}
.footpart.footpart--slim {
  width: 43.05555556%;
}
.footpart.footpart--slim.footpart--address {
  width: 29.16666667%;
}
.footpart.footpart--slim.footpart--rating {
  width: 56.94444444%;
}
h2 {
  font-size: 42px;
  line-height: 1.28571429;
}
.cb-layout1 .content h2 {
  font-size: 56px;
  line-height: 0.91071429;
}
.cb-layout2 .content h2 {
  font-size: 140px;
  line-height: 1;
}
@media (max-width: 1550px) {
  .cb-layout2 .content h2 {
    font-size: 90px;
    line-height: 0.88888889;
  }
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 1.2195122%;
  margin-left: 1.2195122%;
  width: 97.56097561%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area .tiny {
  width: 20%;
}
.section--one .area > .slim .part {
  width: 100%;
}
.section--two .area {
  width: 100%;
}
.section--two .area > .unit {
  margin-right: 3.04878049%;
  margin-left: 3.04878049%;
  width: 93.90243902%;
}
.section--two .area .cb-album .body,
.section--two .area .head,
.section--two .area .foot,
.section--two .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim {
  width: 43.90243902%;
}
.section--two .area > .slim .head,
.section--two .area > .slim .foot,
.section--two .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim .tiny {
  width: 20%;
}
.section--one .area {
  width: 102.5%;
  margin-left: -1.25%;
  display: flex;
  flex-wrap: wrap;
}
.section--two .area {
  width: 106.49350649%;
  margin-left: -3.24675325%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.section--two .area .unit.wide.seam {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section--two .area .unit.wide.seam .body {
  width: 1000px;
  max-width: 100%;
}
.section--two .area .unit.wide.flat .body {
  width: 1000px;
  max-width: 100%;
}
.section--two .area .unit.slim.seam {
  width: 37.66233766%;
}
.section--two .area .unit.slim .body {
  width: 102.77777778%;
  margin-left: -1.38888889%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.section--two .area .unit.slim .part.tall {
  width: 97.2972973%;
  margin-left: 1.35135135%;
  margin-right: 1.35135135%;
}
.section--two .area .unit.slim .part.tiny {
  width: 17.2972973%;
  margin-left: 1.35135135%;
  margin-right: 1.35135135%;
}
/*# sourceMappingURL=./screen-large.css.map */